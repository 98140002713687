<script>
import Create from './create/Create.vue'
import AutoComplete from './create/AutoComplete.vue'

export default {
  components: {Create, AutoComplete},
  data() {
    return {
      autocompleted: false
    }
  },
  mounted() {
    setTimeout(()=> {
        this.$refs.autocomplete.autocompleteOrder(this.$route.params.id)
    }, 1000)
  }
}
</script>

<template>
  <template v-if="autocompleted">
    <Create perform="update"/>
  </template>
  <div v-else class="text-center">
    <b-spinner></b-spinner>
  </div>
  <AutoComplete v-show="false" perform="update" ref="autocomplete" @autocompleted="autocompleted = $event"/>
</template>